<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">{{ $t('messages.content') + ': ' + MessageContent }}</div>
        </div>
    </div>
</template>

<script>
import { BoMessagesApi } from '../../../../service/BoMessagesApi';

export default {
    name: 'messageContent',
    props: {
        messageId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            BoMessagesApi: new BoMessagesApi(),
            MessageContent: '',
        };
    },
    mounted() {
        this.BoMessagesApi.getMessageContent(this.messageId)
            .then((response) => {
                this.MessageContent = response.data;
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
            });
    },
};
</script>