<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <Toast />
                <h5>{{ $t('menu.messages') }}</h5>
                <Fieldset :legend="$t('messages.sendNewMessage')" :toggleable="true" :collapsed="addContentCollapsed">
                    <div class="p-grid">
                        <div class="p-col-12 p-md-4">
                            <h6 class="p-col-12">Message Details</h6>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-10">
                                    <span class="p-float-label">
                                        <InputText style="width: 100%" type="text" v-model="message.subject" />
                                        <label>{{ $t('messages.subject') }}</label>
                                    </span>
                                </div>
                            </div>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-10">
                                    <span class="p-float-label">
                                        <Textarea :autoResize="true" rows="5" style="width: 100%" type="text" v-model="message.content" />
                                        <label>{{ $t('messages.content') }}</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-4">
                            <h6 class="p-col-12">Send to</h6>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-6">
                                    <span class="p-float-label">
                                        <MultiSelect :showClear="true" style="width: 100%" v-model="bUnitModel" :options="bUnitsArray" optionLabel="name" :filter="!this.isMobile()" />
                                        <label>{{ $t('dropdown.placeholder.franchises') }}</label>
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-6 p-mt-3">
                                    <InputSwitch v-model="allBUnits" />
                                    <label class="p-ml-2">{{ $t('messages.allFranchises') }}</label>
                                </div>
                            </div>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-md-6">
                                    <span class="p-float-label">
                                        <MultiSelect style="width: 100%" v-model="shopsModel" :options="shops" optionLabel="name" :filter="!this.isMobile()" />
                                        <label>{{ $t('dropdown.placeholder.shops') }}</label>
                                    </span>
                                </div>
                                <div class="p-col-12 p-md-6 p-mt-3">
                                    <InputSwitch v-model="allShops" />
                                    <label class="p-ml-2">{{ $t('messages.allShops') }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3">
                            <h6 class="p-col-12">Message options</h6>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-mt-4">
                                    <InputSwitch v-model="message.perUser" />
                                    <label class="p-ml-2">{{ $t('messages.perUser') }}</label>
                                </div>
                            </div>
                            <div class="p-field p-grid">
                                <div class="p-col-12 p-mt-4">
                                    <InputSwitch v-model="message.mustRead" />
                                    <label class="p-ml-2">{{ $t('messages.mustRead') }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-1 p-text-right">
                            <Button @click="addNewMessage()" :disabled="disableSubmit" :label="$t('buttons.add')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                        </div>
                    </div>
                </Fieldset>
                <div class="card p-mt-2">
                    <DataTable
                        :lazy="true"
                        :value="items"
                        :paginator="true"
                        :rows="perPage"
                        :loading="loading"
                        class="p-mt-3"
                        :totalRecords="totalPages"
                        @page="onPage($event)"
                        @sort="onSort($event)"
                        @filter="onFilter($event)"
                        ref="datatable"
                        responsiveLayout="scroll"
                        :paginatorBottom="true"
                        v-model:expandedRows="expandedRows"
                    >
                        <Column>
                            <template #body="slotProps">
                                <span class="indexColumn">{{ slotProps.index + 1 }}</span>
                            </template>
                        </Column>
                        <Column :expander="true" headerStyle="width: 3rem" />
                        <Column field="date" :header="$t('sportsbets.date')" :sortable="true">
                            <template #body="slotProps">{{ formatCSDateAndTime(slotProps.data.date) }} </template>
                        </Column>
                        <Column :header="$t('system.code')" field="sentBy" :sortable="true"> </Column>
                        <Column :header="$t('system.shop')" field="subject" :sortable="true"> </Column>
                        <Column :header="$t('system.shops')" field="shops" :sortable="true"> </Column>
                        <Column :header="$t('system.franchises')" field="franchises"> </Column>
                        <template #expansion="slotProps">
                            <MessageContent :messageId="slotProps.data.id"></MessageContent>
                        </template>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoMessagesApi } from '../../../service/BoMessagesApi';
import { BoApi } from '../../../service/BoApi';
import { uiSettings } from '../../../settings/uiSettings';
import MessageContent from './Components/MessageContent.vue';

export default {
    name: 'messages',
    components: {
        MessageContent,
    },
    data() {
        return {
            items: [],
            BoMessagesApi: new BoMessagesApi(),
            BoApi: new BoApi(),
            shops: [],
            shopsModel: [],
            disableSubmit: false,
            allShops: true,
            allBUnits: true,
            changeAllShops: true,
            changeAllBunits: true,
            onlyMine: false,
            bUnitModel: [],
            bUnitsArray: [],
            message: { perUser: false, mustRead: false, subject: '', content: '' },
            crtPage: 0,
            perPage: uiSettings.tableRows,
            totalPages: 0,
            loading: true,
            addContentCollapsed: true,
            expandedRows: [],
        };
    },
    mounted() {
        this.getShops();
        this.getBUnits();
        this.readItems();
    },
    computed: {},
    watch: {
        shopsModel() {
            if (this.shopsModel.length > 0) {
                this.allShops = false;
            }
        },
        bUnitModel() {
            if (this.bUnitModel.length > 0) {
                this.allBUnits = false;
            }
        },
        allBUnits() {
            if (this.allBUnits) {
                this.bUnitModel = [];
            }
        },
        allShops() {
            if (this.allShops) {
                this.shopsModel = [];
            }
        },
    },
    methods: {
        getShops() {
            this.BoApi.getShops('')
                .then((shopsResponse) => {
                    let shopsDdlValues = [];
                    for (var i = 0; i < shopsResponse.data.length; i++) {
                        let shopItem = {
                            name: shopsResponse.data[i].code + ' - ' + shopsResponse.data[i].name,
                            code: shopsResponse.data[i].code,
                            _id: shopsResponse.data[i]._id,
                        };
                        shopsDdlValues.push(shopItem);
                    }
                    this.shops = shopsDdlValues;
                    this.addShops = shopsDdlValues;
                    this.shops.unshift(this.shopsModel);
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        getBUnits() {
            this.BoApi.getBUnitsDdl()
                .then((BUresponse) => {
                    this.bUnitsArray = BUresponse.data;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                });
        },
        onPage(event) {
            this.readItems(event.page + 1);
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
        },
        readItems(page = 1) {
            this.showReportData = false;
            this.isReportLoading = true;
            const params = {
                sentByMe: this.onlyMine,
                per_page: this.perPage,
                page: page,
                sort: this.sort,
            };
            this.BoMessagesApi.getSentMessages(params)
                .then((response) => {
                    if (response.data.data != null) {
                        this.items = response.data.data;
                        this.tableKey++;
                    }
                    this.totalPages = response.data.last_page;
                    this.loading = false;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showError = true;
                    this.errorKeyCount++;
                    this.isReportLoading = false;
                });
        },
        addNewMessage() {
            this.disableSubmit = true;
            this.message.toShops = null;
            this.message.toFranchises = null;
            if (this.allShops) {
                this.message.toShops = [];
            } else {
                if (this.shopsModel.length > 0) {
                    this.message.toShops = this.shopsModel.map((item) => {
                        return item._id;
                    });
                }
            }
            if (this.allBUnits) {
                this.message.toFranchises = [];
            } else {
                if (this.bUnitModel.length > 0) {
                    this.message.toFranchises = this.bUnitModel.map((item) => {
                        return item._id;
                    });
                }
            }
            if (this.notNullOrEmptyField(this.message.subject) && this.notNullOrEmptyField(this.message.subject)) {
                this.BoMessagesApi.sendMessage(this.message)
                    .then(() => {
                        this.showToast('success', this.$t('alerts.okAction'));
                        this.readItems();
                        this.clearAddContent();
                        this.disableSubmit = false;
                    })
                    .catch((error) => {
                        this.disableSubmit = false;
                        this.checkForUnauthorizedError(error);
                        this.showToast('error', this.$t('alerts.generalError'));
                    });
            } else {
                this.disableSubmit = false;
                this.showToast('error', this.$t('messages.mandatorySubjectAndContent'));
            }
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        clearAddContent() {
            this.addContentCollapsed = true;
            this.message = { perUser: false, mustRead: false, subject: '', content: '' };
            this.allShops = true;
            this.allBUnits = true;
            this.mustRead = false;
            this.perUser = false;
        },
    },
};
</script>