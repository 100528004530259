import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class BoMessagesApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].boApiUrl+'/inMail/',
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }
    getSentMessages(params) {
        return this.Api.get('getSentMessages', {params})
    }
    sendMessage(message) {
        return this.Api.post('sendMessage ', message)

    }
    getMessageContent(messageId) {
        return this.Api.get('getMessageContent?messageId=' + messageId)
    }
}

export {
    BoMessagesApi
}
